@import "../../assets/styles/colors.scss";
@import "../../assets/styles/variables.scss";

.multiSelectToolbar {
  display: flex;
  flex: 1;
  align-items: center;
  min-height: 48px;
  left: 0 !important;
  justify-content: space-between;

  .multiSelectToolbarLeft {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 100px;
  }

  .multiSelectToolbarRight {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  div {
    color: $secondary-color;
    font-size: 14px;
    line-height: 48px;
    text-align: left;
    height: 100%;

 

    &.activeToolbarItemsCount {
      padding-left: 10px;
      font-size: 14px;
      font-weight: 600;
      min-width: 140px;
    }

    &.activeToolbarSum {
      font-size: 14px;
    }

 

    &.activeToolbarClear {
      margin-left: 10px;
    }
  }
}
